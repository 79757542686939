<!-- nice본인인증 결과를 수신하는 페이지(2023-05-18, James)-->
<template>
  <div></div>
</template>

<script>
export default {
  name: 'nice.cert.success.popup',
  data() {
    return {
      nice: {
        encodedata: '', // 회신 데이터
        reqseq: '', // request_id (session 에 담아져 있음)
      },
      paramToParent: {
        niceinfo: Object,
        memberinfo: Object,
      },
    };
  },
  mounted() {
    /*********************************************
     * nice본인인증 결과 수신 후처리
     **********************************************/
    this.nice.encodedata = this.$route.query.EncodeData;
    this.nice.reqseq = this.$storage.getSessionStorage('REQ_SEQ');
    this.nice.authtype = 'PARTNER';

    this.$storage.removeSessionStorage('REQ_SEQ');

    this.$http.post('/common/nice-decrypt', this.nice).then((result) => {
      if (result.statusCode === 200 && result.data.return === 0) {
        //let param = Object.assign(result.data.niceinfo, result.data.memberinfo);
        //window.localStorage.setItem('CERT_DATA', JSON.stringify(param));
        //console.log("result.data : " + JSON.stringify(result.data));
        this.paramToParent.niceinfo = result.data.niceinfo;
        this.paramToParent.memberinfo = result.data.memberinfo;
        opener.popupCallback(this.paramToParent);
        //window.opener.niceOk(JSON.stringify(param));
        self.close();
      } else {
        this.$eventBus.$emit('alert', '알림', '[' + result.data.return + ']' + result.data.message);
      }
    });
  },
};
</script>

<style scoped />
